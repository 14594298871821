import React from "react"
import RealizationsImages from "../../components/RealizationImages"
import "../../styles/gallery.scss"
import { graphql } from "gatsby"

import SEO from "../../components/seo"

const description = "Usługi tapicerskie. Czyszczenie, renowacja i regeneracja mebli skórzanych. Naprawa skórzanej tapicerki samochodowej. Zakład tapicerski Konin."
const keywords = ["galeria tapicerstwo", "zdjecia tapicerstwo"]
const GalleryUph = ({ data }) => (
  <>
    <SEO
      title="Galeria | Tapicerstwo"
      description={description}
      keywords={keywords}
    />
    <div className="gallery container">
      <h3 className="title">GALERIA</h3>
      <RealizationsImages
        images={data.containers}
      />
    </div>
  </>


)



export const query = graphql`
  {
    containers: allFile(filter: {extension: {regex: "/(jpeg|jpg|gif|png)/"}, name: {regex: "/relu/"}}) {
    edges {
      node {
        childImageSharp {
          fluid (maxWidth: 500) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }

  }
`

export default GalleryUph